import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
// React boostrap
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
//SEO Matter
import Seo from "../components/seo";
const IndexPage = () => (
  <div id="Index">
    <Seo
      title="Aparcabicicletas | Soportes para Bicicletas | Marquesinas para Bicicletas - BIKESAFE"
      description="Bike Safe ofrece soluciones especializadas en aparcabicicletas, soportes y marquesinas para bicicletas, diseñadas y personalizadas para adaptarse a cualquier necesidad de almacenamiento y aparcamiento de bicicletas."
    />
    <Layout>
      <Container fluid className="p-0">
        <Row className="mb-5">
          <Col className="text-center no-padding-or-margin">
            <div className="parallax-home">
              <div className="home-title">
                <h1 className="home-title-text">Especialistas</h1>
                <h1 className="home-title-text-under opacity">
                  en soluciones de aparcabicis
                </h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">
              Aparcabicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/61">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/61/homepage-61.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Aparcabicicletas de dos niveles
              </h3>
              <p>
                ¡Ahorro de espacio, máxima eficiencia y excelente relación
                calidad-precio! El aparcabicicletas de dos niveles es un sistema
                de almacenamiento innovador y fácil de usar que permite...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/230">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/230/homepage-230.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                BikeSafe Aparcabicicletas de dos niveles
              </h3>
              <p>
                El aparcabicicletas de dos niveles BikeSafe es un sistema de
                almacenamiento innovador y fácil de usar, diseñado para ahorrar
                espacio y ofrecer la máxima eficiencia y comodidad...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/80">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/80/homepage-80.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Aparcabicicletas vertical independiente
              </h3>
              <p>
                Una variación de nuestro popular aparcabicicletas de pared. Este
                modelo independiente permite a los usuarios aprovechar al máximo
                la superficie del suelo. La estructura se puede fijar con
                tornillos...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/88">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/88/homepage-88.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Aparcabicicletas semivertical</h3>
              <p>
                Para aprovechar al máximo el espacio cuando escasea. El
                aparcabicicletas semivertical se puede utilizar en interiores o
                exteriores. La rueda trasera se puede asegurar al
                aparcabicicletas con un candado tipo D o una cadena...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">
              Soportes Aparcabicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/99/homepage-99.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Soporte Bici Salamanca Tradicional Galvanizado
              </h3>
              <p>
                El diseño clásico y popular es perfecto para aquellos que buscan
                una excelente relación calidad-precio, escaso mantenimiento y
                un...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/100/homepage-100.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Soporte Bici Salamanca Tradicional
              </h3>
              <p>
                La forma permite que el cuadro y la rueda de la bicicleta se
                canden de forma segura al soporte. El diseño clásico y popular
                es...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">
              Marquesinas para Bicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/233">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/233/homepage-233.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Marquesina Gijón</h3>
              <p>
                Disponible en acabado galvanizado o revestimiento en color RAL,
                la marquesina Gijón incluye un soporte para bicicletas tipo
                toastrack galvanizado. Con techo y estructura resistentes que...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/38">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/38/homepage-38.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Marquesina Ark</h3>
              <p>
                Diseñada para maximizar la capacidad de almacenamiento, la
                marquesina Ark permite guardar bicicletas en dos filas,
                optimizando el espacio disponible. Disponible con diversas
                opciones de...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/22">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="./product/22/homepage-22.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Marquesina Bristol</h3>
              <p>
                Equipada con características versátiles, la marquesina Bristol
                se adapta a cualquier entorno. Construida en acero galvanizado y
                con fijaciones robustas, ofrece una solución resistente y
                duradera...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">
              Especialistas en aparcabicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="p-2">
            <p>
              Especializado en aparcamiento y almacenamiento de bicicletas, Bike
              Safe diseña, personaliza y suministra una amplia gama de productos
              para adaptarse a sus necesidades.
            </p>
            <p>
              Bike Safe es el proveedor oficial de aparcabicicletas, racks y
              marquesinas para bicicletas del Programa Barclays Cycle
              Superhighway de Transport for London, cubriendo todas las rutas
              existentes.
            </p>
            <p>
              El programa Barclays Cycle Superhighways, equipado con nuestras
              marquesinas y aparcabicicletas, ofrece nuevas rutas ciclistas que
              conectan el centro de Londres con las afueras, proporcionando
              trayectos más seguros, rápidos y directos para los ciclistas.
            </p>
            <p>
              Este proyecto ofrece miles de nuevos espacios de aparcamiento para
              bicicletas y formación gratuita o subvencionada para desplazarse
              al trabajo en bicicleta. Además, mejora las instalaciones para
              ciclistas en el lugar de trabajo, todo a través de Bike Safe, el
              especialista en aparcabicicletas en el Reino Unido.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </div>
);

export default IndexPage;
